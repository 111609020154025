import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import DBProvider from './contexts/DatabaseContext';
import HeaderProvider from './contexts/LayoutContext';
import { Layout } from 'antd';
import { Sider, Header } from '@src/components/';
import { Assessments, Statements, Projects, Settings, DBTest } from './pages/';
import './App.less';

const App: React.FC = () => {
  return (
    <DBProvider>
      <HeaderProvider>
        <BrowserRouter>
          <Switch>
            <Layout className="site-layout">
              <Sider></Sider>
              <Layout>
                <Header></Header>
                <Route path="/assessments" component={Assessments} />
                <Route path="/statements" component={Statements} />
                <Route path="/settings" component={Settings} />
                <Route path="/projects" component={Projects} />
                <Route path="/dbtest" component={DBTest} />
                <Redirect to="/projects" />
              </Layout>
            </Layout>
          </Switch>
        </BrowserRouter>
      </HeaderProvider>
    </DBProvider>
  );
};

export default App;
