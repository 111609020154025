import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { BulbFilled, ProfileFilled } from '@ant-design/icons';
import { InputNumber, Tooltip } from 'antd';
import { ISection } from '@src/interfaces';
import { useDB } from '@src/hooks';
import { MarkingComponent, List } from '@src/components';
import './index.less';
import { getDatasetBySectionIdFromStudents, getStatementVectorByStatementIds } from '@src/lib/project';
import { FairRF } from '@src/lib/rf';

const COMMENT_LIMIT = 5; // enable ml predict iff >5 comment in statement bank
const RANGE_OFFSET = 5;
const ACCEPT_RANGE = 30;
const INRANGEPOP = 'Your mark seems to be fit in.';
const OUTOFRANGEPOP = 'Your mark seemed to be out of range, consider revising them according to the suggestions.';
const UNABLEPOP =
  'The system is unable to predict the mark due to lack of information. Try finish marking some assessments and check again';

const SectionComponent: React.FC<{ section: ISection }> = ({ section }) => {
  const { project, student } = useDB();
  const studentMark = useMemo(() => student?.sections[section.id].mark, [student, section]);
  const [lowest, setLowest] = useState(0);
  const [highest, setHighest] = useState(0);
  const [isPredictable, setIsPredictable] = useState(true);
  const [isInRange, setIsInRange] = useState(false);

  const handleMarkChange = useCallback(
    (value) => {
      if (value && student && project) {
        const mark = value as number;
        student && project?.markStudent(student.id, section.id, mark);
      }
    },
    [student, project, section]
  );

  useEffect(() => {
    if (project && student) {
      const hyperParams = project.hyperparameters;
      const statements = project.sections[section.id].statements;
      const students = Object.values(project.students).filter((_student) => _student.id !== student.id);
      const studentVector = getStatementVectorByStatementIds(statements, student.sections[section.id].statementIds);
      const { xSet, ySet } = getDatasetBySectionIdFromStudents(statements, section.id, students);
      setIsPredictable(false);
      console.log(xSet, ySet, hyperParams);
      if (Array.isArray(xSet) && xSet.length > 0 && xSet[0].length > COMMENT_LIMIT) {
        const rf = new FairRF(hyperParams);
        rf.train(xSet, ySet);
        const mse = rf.mse();
        const predict = rf.predict([studentVector])[0];
        setLowest(Number((predict - RANGE_OFFSET).toFixed(1)));
        setHighest(Number((predict + RANGE_OFFSET).toFixed(1)));
        setIsPredictable(!(mse > ACCEPT_RANGE));
        console.log('mes, vector for predict, predicted value', mse, studentVector, predict);
      }
    }
  }, [student, section, project]);

  useEffect(() => {
    if (student) {
      if (lowest <= student.sections[section.id].mark && student.sections[section.id].mark <= highest) {
        setIsInRange(true);
      } else {
        setIsInRange(false);
      }
    }
  }, [lowest, highest, student, section]);

  return (
    <div className="section__container">
      <div className="section__wrap">
        <div className="section__wrap-left">
          <ProfileFilled className="section__wrap-icon" />
          <div className="section__wrap-title">{section.name}</div>
        </div>
        <div className="section__wrap-right">
          <Tooltip
            placement="top"
            title={isPredictable && isInRange ? INRANGEPOP : isPredictable ? OUTOFRANGEPOP : UNABLEPOP}
          >
            <BulbFilled
              className="section__wrap-pred-icon"
              style={{ color: isPredictable && isInRange ? '#34C759' : isPredictable ? 'red' : 'gray' }}
            />
            <div
              className="section__wrap-pred-mark"
              style={{ color: isPredictable && isInRange ? '#34C759' : isPredictable ? 'red' : 'gray' }}
            >
              {lowest}-{highest}
            </div>
          </Tooltip>
          <InputNumber
            min={0}
            max={project?.sections[section.id].mark}
            defaultValue={studentMark}
            bordered={false}
            onChange={handleMarkChange}
          />
          <div className="section__wrap-total-mark">/{project?.sections[section.id].mark}</div>
        </div>
      </div>
      <MarkingComponent sectionId={section.id} student={student!} />
      <List section={section} commentType="student" />
    </div>
  );
};

export default SectionComponent;
