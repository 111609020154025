import React, { createContext, useContext, useReducer } from 'react';
import { ILayoutState, ILayoutContext, ILayoutAction } from '@src/interfaces';

const initLayoutState: ILayoutState = {
  title: 'Fair Assessment',
  sidebarDisableKeys: []
};

const LayoutContext = createContext<ILayoutContext>({
  layoutState: initLayoutState,
  updateLayoutState: () => {}
});

const layoutReducer = (state: ILayoutState, action: ILayoutAction): ILayoutState => {
  if (action.type === 'all') {
    return { ...initLayoutState, ...action.state };
  } else if (action.type === 'partial') {
    return {
      ...state,
      ...action.state
    };
  }
  return initLayoutState;
};

const LayoutContextComp: React.FC = ({ children }) => {
  const [layoutState, updateLayoutState] = useReducer(layoutReducer, initLayoutState);
  return <LayoutContext.Provider value={{ layoutState, updateLayoutState }}>{children}</LayoutContext.Provider>;
};

export default LayoutContextComp;
export const useLayout = () => useContext(LayoutContext);
