import React from 'react';
import Student from '../Student';
import { Row, Col } from 'antd';
import { IStudent } from '@src/interfaces';
import '../index.less';

const StudentsList: React.FC<{ students: Array<IStudent> }> = ({ students }) => {
  return (
    <div className="comment_list">
      <Row className="students_container" style={{ marginTop: '15px' }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {students.map((student, i) => (
          <Col key={i} style={{ marginTop: '15px' }}>
            <Student student={student} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default StudentsList;
