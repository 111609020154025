import React, { useEffect, useState } from 'react';
import { IStudent } from '@src/interfaces';
import { useDB } from '@src/hooks';
import Select from 'react-select/creatable';
import './index.less';

const Menu = (props: any) => {
  return <div className="section_menu">{props.children}</div>;
};

const MenuList = (props: any) => {
  return <div className="section_menulist">{props.children}</div>;
};

const customComponents = {
  DropdownIndicator: null,
  Menu,
  MenuList
};

const MarkingComponent: React.FC<{ sectionId: string; student: IStudent }> = ({ sectionId, student }) => {
  const { project } = useDB();
  const statementBank = project?.sections[sectionId].statements.map((v) => {
    return { label: v.text, value: v.id };
  });
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<{ label: string; value: string }[]>([]);
  useEffect(() => {
    if (student && project) {
      const studentStatementValues = project.sections[sectionId].statements
        .filter((statement) => student.sections[sectionId].statementIds.includes(statement.id))
        .map((statement) => {
          return {
            label: statement.text,
            value: statement.id
          };
        });
      setValue(studentStatementValues);
    }
  }, [sectionId, student, project]);

  const handleChange = (value: any, actionMeta: any) => {
    console.log(actionMeta);
    switch (actionMeta.action) {
      case 'remove-value':
      case 'deselect-option':
      case 'clear':
      case 'select-option':
        if (project && student) {
          project.updateStatementsOfStudent(
            sectionId,
            student.id,
            value.map((v: any) => v.value)
          );
        }
        setValue(value);
        break;
      case 'create-option':
        if (project) {
          const statement = project.addStatement(sectionId, actionMeta.option.label);
          project.addStatementsToStudent(sectionId, student.id, [statement.id]);
        }
        break;
    }
  };

  const handleInputChange = (inputValue: string) => {
    setInputValue(inputValue);
  };

  return (
    <div className="marking__container">
      <Select
        options={statementBank}
        components={customComponents}
        inputValue={inputValue}
        isClearable
        isMulti
        onChange={handleChange}
        onInputChange={handleInputChange}
        placeholder="Type here to search and create..."
        value={value}
        styles={{
          container: (provided) => ({
            ...provided,
            border: '1px solid rgba(57, 64, 229, 0.5)',
            boxShadow: '7px 7px 0 rgba(0, 122, 255, 0.25)',
            borderRadius: '15px',
            overflow: 'hidden',
            minHeight: '50px'
          }),
          control: (provided) => ({
            ...provided,
            border: 'none',
            boxShadow: 'none',
            background: '#fff',
            borderRadius: '15px',
            overflow: 'hidden'
          })
        }}
      />
    </div>
  );
};

export default MarkingComponent;
