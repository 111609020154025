import React from 'react';
import { useDB } from '@src/contexts/DatabaseContext';
import Select from 'react-select';

const Index: React.FC = () => {
  const { db, setProjectByID, project, setStudentByID } = useDB();
  const projects = Object.values(db.projects).map((p) => {
    return { value: p.id, label: p.name };
  });
  const students = project
    ? Object.values(project?.students).map((s) => {
        return { value: s.id, label: s.name };
      })
    : [];
  // const opt;
  return (
    <div>
      <h1>Database Testing/Debugging Interface</h1>
      <div>
        <p>Current Project: {project?.name}</p>
      </div>
      <h1>Pick a Project</h1>
      <Select onChange={(e) => setProjectByID(e?.value || '')} options={projects} />
      {project ? (
        <>
          <h1>Pick a Student</h1>
          <Select onChange={(e) => setStudentByID(e?.value || '')} options={students} />
        </>
      ) : null}
    </div>
  );
};

export default Index;
