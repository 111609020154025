import React, { useEffect } from 'react';
import { useDB, useLayout } from '@src/hooks';
import { Statistic, Tooltip } from 'antd';
import { RightCircleFilled } from '@ant-design/icons';
import Section from './Section';
import './index.less';

const Marking = () => {
  const { project, setStudentByID } = useDB();
  let { student } = useDB();
  const { updateLayoutState } = useLayout();

  const handleNextClick = () => {
    if (project?.students) {
      const studentIdArr = Object.keys(project.students);
      if (student) {
        let newStudentIdIndex = studentIdArr.indexOf(student?.id);
        if (newStudentIdIndex < studentIdArr.length - 1)
          setStudentByID(Object.keys(project.students)[newStudentIdIndex + 1]);
      }
    }
  };

  useEffect(() => {
    updateLayoutState({
      type: 'partial',
      state: {
        title: student?.name,
        subTitle: student?.username + '   ' + project?.name,
        extra: [
          <Statistic
            key="totalMark"
            value={
              student
                ? Object.values(student.sections)
                    .map((item) => item.mark)
                    .reduce((prev, cur) => prev + cur)
                : 0
            }
            suffix={'/' + project?.totalMark}
          />
        ]
      }
    });
  }, [student, project, updateLayoutState]);

  return (
    <>
      {project?.sections &&
        Object.values(project.sections).map((v, i) => {
          return <Section key={i} section={v} />;
        })}
      <Tooltip title="Next student">
        <RightCircleFilled className="next__button" onClick={handleNextClick} />
      </Tooltip>
    </>
  );
};

export default Marking;
