import React, { useMemo, useState } from 'react';
import { List, Button, Input, InputNumber, message } from 'antd';
import { IProject, ISection } from '@src/interfaces';
import '../index.less';

const AddSection: React.FC<{ project: IProject | undefined; section: ISection }> = ({ project, section: _section }) => {
  const [section, setSection] = useState<ISection>(_section);
  const isExisted = useMemo(() => Object.keys(project?.sections || {}).find((id) => id === section.id), [
    project,
    section
  ]);
  return (
    <List.Item
      actions={[
        <Button
          type="text"
          className="list_option"
          key="project-settings"
          onClick={() => {
            console.log('sections', project?.sections);
            project?.addSection(section);
            console.log("added section");
            if ( project){
              if (project?.checkTotalMark() == false){
                message.warning("The sections don't add up to the total mark inputted, please check you inputs")
              }
            }
          }}
        >
          {isExisted ? 'Update this' : 'Add this'}
        </Button>,
        <Button
          disabled={!isExisted}
          type="text"
          className="list_option"
          key="project-export"
          onClick={() => {
            project?.deleteSection(section.id);
          }}
        >
          Delete
        </Button>
      ]}
    >
      <div className="section__config">
        <Input
          placeholder="Name"
          defaultValue={section.name}
          size="large"
          onChange={(e) => {
            const value = e.target.value;
            setSection((prev) => ({ ...prev, name: value }));
          }}
        />
        <InputNumber
          className="mark_input"
          placeholder="Mark"
          min={0}
          size="large"
          defaultValue={section.mark}
          onChange={(value) => {
            setSection((prev) => ({ ...prev, mark: value as number }));
          }}
        />
      </div>
    </List.Item>
  );
};

export default AddSection;
