import React, { useState, createContext, useContext, useCallback } from 'react';
import { db as _db } from '@src/lib/database';
import { IDBContext, IStudent, IProject, IDatabase } from '@src/interfaces';

export const DBContext = createContext<IDBContext>({
  db: _db
} as IDBContext);

const DBContextComp: React.FC = ({ children }) => {
  const [db, setDB] = useState<IDatabase>(_db);
  const [project, setProject] = useState<IProject | undefined>();
  const [student, setStudent] = useState<IStudent | undefined>();

  const clone = useCallback((object: any) => Object.assign(Object.create(Object.getPrototypeOf(object)), object), []);

  document.addEventListener('onDBChange', () => {
    setDB(clone(db));
    setProject(project && clone(project));
    setStudent(student && clone(student));
  });

  const setProjectByID = (ID: string) => {
    setProject(db.projects[ID]);
  };

  const setStudentByID = (ID: string) => {
    if (project) {
      setStudent(project.students[ID]);
    }
  };
  return (
    <DBContext.Provider value={{ db, project, student, setProjectByID, setStudentByID }}>{children}</DBContext.Provider>
  );
};

export default DBContextComp;
export const useDB = () => useContext(DBContext);
