import React from 'react';

const ExportToFileButton: React.FC<{ dataString: string; format: 'json' | 'csv'; filename: string }> = ({
  dataString,
  format,
  filename
}) => {
  const dataStr = `data:text/${format};charset=utf-8,${dataString}`;
  return (
    <a key="list-loadmore-edit" className="ant-btn ant-btn-link" href={dataStr} download={`${filename}.${format}`}>
      Download
    </a>
  );
};

export default ExportToFileButton;
