import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { nanoid } from 'nanoid';
import moment from 'moment';
import {
  Menu,
  List,
  Input,
  InputNumber,
  Button,
  Typography,
  Modal,
  message,
  DatePicker,
  Upload,
  Select,
  Divider,
  Row
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import { getStudentsFromCSVString, Project, getStatementBankFromJSONString } from '@src/lib/project';

import {
  CaretUpFilled,
  InfoCircleFilled,
  CalendarFilled,
  EditFilled,
  FolderOpenFilled,
  DeleteFilled,
  ExperimentFilled,
  CodeSandboxCircleFilled,
  LeftCircleFilled,
  UploadOutlined
} from '@ant-design/icons';
import { useDB, useLayout, useQuery } from '@src/hooks';
import './index.less';
import AddSection from './AddSection';
import { db as _db } from '@src/lib/database';
import { useHistory } from 'react-router';
import { SelectContainer } from 'react-select/src/components/containers';
import { RefSelectProps } from 'antd/lib/select';
import Student from '../Assessments/Students/Student';

const SettingsMenu: React.FC = () => {
  return (
    <Menu mode="horizontal" defaultActiveFirst>
      <Menu.Item key="basic_settings">Basic Settings</Menu.Item>
      <Menu.Item key="sb_settings">Statement Bank Settings</Menu.Item>
      <Menu.Item key="advanced_settings">Advanced Settings</Menu.Item>
    </Menu>
  );
};

interface IConfigObj {
  title: string | React.ReactNode;
  icon: React.ReactNode;
  description: string;
  actions?: Array<React.ReactNode>;
  extra?: React.ReactNode;
}

type IPageType = 'newProject' | 'settings';

const Settings: React.FC = () => {
  const { db, project: _project , setProjectByID} = useDB();

  const { Option } = Select;

  const query = useQuery();
  const pageType = (query.get('pageType') || 'settings') as IPageType;
  const projectId = query.get('projectId');
  const history = useHistory();
  const [tempProject, setTempProject] = useState(
    new Project('New Project', nanoid(), new Date().toString(), [], {}, 0, _db, { nEstimators: 100 })
  );

  const clone = useCallback((object: any) => Object.assign(Object.create(Object.getPrototypeOf(object)), object), []);

  document.addEventListener('onDBChange', () => {
    setTempProject(clone(tempProject));
  });

  const project = useMemo(
    () =>
      ((pageType: IPageType, projectId: string | null) => {
        if (pageType === 'newProject') {
          return tempProject;
        }

        if (projectId) {
          return db.projects[projectId];
        }

        return _project;
      })(pageType, projectId),
    [_project, db, pageType, projectId, tempProject]
  );
  const { updateLayoutState } = useLayout();
  const { Title } = Typography;
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [isStudentsModalVisible, setIsStudentsModalVisible] = useState(false);
  const [isSBModalVisible, setIsSBModalVisible] = useState(false);
  const { TextArea } = Input;
  const [isSectionModalVisible, setIsSectionModalVisible] = useState(false);

  //For the importing section part
  const [selectedSection, setSelectedSection] = React.useState<string>('-1');
  const [selectedStatementBanks, setStatementBanks] = React.useState<any[]>([]);
  const [selectedFileName, setFileNames] = React.useState<any[]>([]);

  const [selectProject, setSelectedProject] = React.useState<string>('-1');
  const [selectSectionsDropdown, setselectSectionDropdown] = React.useState<string[]>([]);
  const [SelectedSectionProject, setSelectedSectionProject] = React.useState<string>('-1');

  const handleCSVUpload = useCallback(
    (file: RcFile) => {
      file.text().then((text) => {

        if (project) {
          const prevStudentsNum = Object.values(project.students).length || 0;
          project.addStudentsFromCSVUpload(text)

          //This doesn't add the new sections - bug should be now fixed
          //project.addStudents(...students);
          
          let dupes = project.checkForDuplicateStudents();
          const curStudentsNum = Object.values(project.students).length || 0;
          message.success(`${curStudentsNum - prevStudentsNum} Students has been added to the project.`);

          if ( dupes.length > 0 ){

            setIsWarningVisible(true);

          }

        }
      
      });

      return false;
    },
    [project]
  );

  const getOptions = () => {
    var options = [];

    for (var x in project?.sections) {
      let name: any = project?.sections[x].name;
      let id: any = project?.sections[x].id;
      options.push(<Option value={id}> {name} </Option>);
    }

    return options;
  };

  const getProjects = () => {
    var options = [];

    for (var x in db.projects) {
      let value = db.projects[x].id;
      let name = db.projects[x].name;
      if (name !== project?.name) {
        options.push(<Option value={value}> {name} </Option>);
      }
    }

    return options;
  };

  const getProjectSections = (value: string) => {
    setSelectedProject(value);
    let newOptions = [];
    for (var x in db.projects[value].sections) {
      let name: any = db.projects[value].sections[x].name;
      newOptions.push(name);
    }
    setselectSectionDropdown(newOptions);
  };

  const handleJSONStatementBank = useCallback((file: RcFile) => {
    setFileNames((FN) => {
      FN.push(file.name);
      return FN;
    });

    file.text().then((text) => {
      const statementBank = getStatementBankFromJSONString(text);
      setStatementBanks((SB) => {
        SB.push(statementBank);
        return SB;
      });
    });

    return false;
  }, []);

  useEffect(() => {
    updateLayoutState({
      type: 'all',
      state: {
        title: 'Settings',
        menu: <SettingsMenu />
      }
    });
  }, [updateLayoutState]);

  const handleChooseStudents = () => {
    setIsStudentsModalVisible(true);
  };

  const handleChooseSB = () => {
    setIsSBModalVisible(true);
  };

  const handleChooseStudentsOk = () => {
    if (selectProject === '-1') {
      message.warning('Select a project');
    } 
    else {
      setIsStudentsModalVisible(false);
      console.log(db.projects[selectProject].students);
      console.log(project?.sections);
      if (project){
        const prevStudentsNum = Object.values(project.students).length || 0;
      
        for (var x in db.projects[selectProject].students) {
          project?.addStudent(
            db.projects[selectProject].students[x].name,
            db.projects[selectProject].students[x].username
          );
          console.log( db.projects[selectProject].students[x].name)
            
        }

        const curStudentsNum = Object.values(project.students).length || 0;
        message.success(`${curStudentsNum - prevStudentsNum} Students has been added to the project.`);
      }

      if (project){
        let dupes = project?.checkForDuplicateStudents();
        if ( dupes.length > 0 ){

          setIsWarningVisible(true);

        }
    }

    }

    console.log(project?.students);
  };

  const handleChooseStudentsCancel = () => {
    setIsStudentsModalVisible(false);
  };

  const handleChooseSBOk = () => {
    if (selectProject == '-1') {
      message.warning('Please select a project');
    }
    // else if (selectProject == project?.id){
    //   message.warning("Can not import from current project")
    // }
    else if (selectedSection === '-1') {
      message.warning('Please select a section');
    } else if (SelectedSectionProject === '-1') {
      message.warning('Please select a section to add to');
    } else {
      setIsSBModalVisible(false);
      console.log(selectedSection);
      console.log(selectProject);
      console.log(SelectedSectionProject);

      //Need to get the id
      for (var x in db.projects[selectProject].sections) {
        let name: any = db.projects[selectProject].sections[x].name;
        console.log(name);
        if (name == selectedSection) {
          let statementsToAdd = db.projects[selectProject].sections[x].statements;

          for (var y in statementsToAdd) {
            project?.addStatement(SelectedSectionProject, statementsToAdd[y].text);
            console.log(statementsToAdd[y].text);
          }
        }
      }
    }
  };

  const handleChooseSBCancel = () => {
    setIsSBModalVisible(false);
  };

  const handleWarningModalCancel = () => {
    
    setIsWarningVisible(false);
    if (project){

      const prevStudentsNum = Object.values(project.students).length || 0;
      
      project?.deleteDuplicates();
      const deleteMessage = message.loading('Removing duplicates', 0);
      setTimeout(deleteMessage, 1000);

      const curStudentsNum = Object.values(project.students).length || 0;
      message.success(`${prevStudentsNum - curStudentsNum} Students have been ignored`);
    }
    
  };

  const hanldeWarningModalConfirm = () => {
    setIsWarningVisible(false);
    const deleteMessage = message.loading('Replacing duplicates', 0);
    setTimeout(deleteMessage, 1000);
    project?.replaceDuplicates();

  };

  const handleSectionModal = () => {
    setIsSectionModalVisible(true);
  };

  const handleSectionOK = () => {
    if (selectedSection === '-1') {
      message.warning('Please select a section');
    } else if (selectedFileName.length === 0) {
      message.warning('Please upload a file');
    } else {
      setIsSectionModalVisible(false);
      //Need to then add the statements to the section
      console.log('add');
      console.log(selectedSection);
      console.log(selectedStatementBanks);

      selectedStatementBanks.forEach((x) => {
        for (var y in x) {
          project?.addStatement(selectedSection, x[y].text);
        }
      });

      setSelectedSection('-1');
      setFileNames([]);
      setStatementBanks([]);
    }
  };

  const handleSectionCancel = () => {
    setIsSectionModalVisible(false);
    setSelectedSection('-1');
  };

  const onChangeSections = (value: any) => {
    setSelectedSection(value);
  };

  const onChangeSectionforProject = (value: any) => {
    setSelectedSectionProject(value);
  };

  const onChangeProjects = (value: any) => {
    getProjectSections(value);
  };

  const onChangeProjectsStudents = (value: any) => {
    setSelectedProject(value);
  };

  const uploadProps = {
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: <DeleteFilled />
    },
    onRemove: (file: any) => {
      let index = selectedFileName.indexOf(file.name);
      setStatementBanks((SB) => {
        const newList = SB.slice(index, 1);
        return newList;
      });

      setFileNames((FN) => {
        return FN.slice(index, 1);
      });
    }
  };

  //#region DataSets
  const basicOptionsGeneral: IConfigObj[] = [
    {
      icon: <InfoCircleFilled className="blue_icon" />,
      title: 'Name',
      description: 'The name of your project',
      extra: (
        <Input
          className="settings_name_input"
          onChange={(e: any) => {
            const value = e.target.value;
            console.log(value);
            project?.setConfigs('name', value);
          }}
          placeholder={'Project Name'}
          defaultValue={project?.name}
          size="large"
        />
      )
    },
    {
      icon: <CalendarFilled className="blue_icon" />,
      title: 'Coursework Start Date',
      description: 'Academic year of this project is automatically set based on this value.',
      extra: (
        <DatePicker
          className="settings_date_input"
          defaultPickerValue={moment(project?.date)}
          defaultValue={moment(project?.date)}
          placeholder="Date"
          onChange={(_, dateString) => {
            project?.setConfigs('date', new Date(dateString));
          }}
          size="large"
        />
      )
    },
    {
      icon: <EditFilled className="blue_icon" />,
      title: 'Coursework Total Mark',
      description: 'The total marks possible for your coursework in this project.',
      extra: (
        <InputNumber
          className="settings_mark_input"
          size="large"
          min={0}
          placeholder="Mark"
          defaultValue={project?.totalMark}
          onChange={(value) => {
            project?.setConfigs('totalMark', value as number);
          }}
        />
      )
    }
  ];
  const addingNewStudentsOptions: IConfigObj[] = [
    {
      icon: <CaretUpFilled className="blue_icon" />,
      title: (
        <Upload className="projects__upload" accept=".csv" beforeUpload={handleCSVUpload}>
          Import Students from Your Computer
        </Upload>
      ),
      description: 'A valid student list should be a csv file containing student name and student Banner IDs.',
      extra: null
    },
    {
      icon: <FolderOpenFilled className="blue_icon" />,
      title: <a onClick={handleChooseStudents}>Import Students from Other Projects</a>,
      description: 'You can include student list from other projects.',
      extra: null
    }
  ];

  const addingNewStatementBankOptions: IConfigObj[] = [
    {
      icon: <CaretUpFilled className="blue_icon" />,
      title: <a onClick={handleSectionModal} className="projects__upload"> Import Statements from Your Computer</a>,
      description: 'A valid statement bank should be a JSON containing statements',
      extra: null
    },
    {
      icon: <FolderOpenFilled className="blue_icon" />,
      title: <a onClick={handleChooseSB}>Import Statement Banks from Other Projects</a>,
      description: 'You can include statement banks from other projects',
      extra: null
    }
  ];

  const advancedOptions: IConfigObj[] = [
    {
      icon: <ExperimentFilled className="blue_icon" />,
      title: 'Estimators',
      description: 'Number of estimators/trees to be used in the Random Forest Model',
      extra: (
        <InputNumber
          className="settings_mark_input"
          size="large"
          min={0}
          placeholder="nEstimators"
          defaultValue={project?.hyperparameters?.nEstimators}
          onChange={(value) => {
            const _value = value as number;
            if (_value < 50) return;
            project?.setConfigs('hyperparameters', {
              nEstimators: _value
            });
          }}
        />
      )
    },
    {
      icon: <CodeSandboxCircleFilled className="blue_icon" />,
      title: 'Max Features',
      description:
        'Max number of features/comments to be trained in each estimator. Default set to be the length of the statement bank',
      extra: (
        <Input
          className="settings_mark_input"
          size="large"
          min={0}
          placeholder="mFeatures"
          onChange={(e: any) => {
            const value = parseInt(e.target.value, 10);
            project?.setConfigs('hyperparameters', {
              maxFeatures: value
            });
          }}
        />
      )
    }
  ];

  //#endregion

  return (
    <div className="list-page-layout page-layout settings__page">
      <div className="title_wrapper" id="basic_position">
        <Title level={3}>Basic Options</Title>
      </div>
      <div className="title_wrapper">
        <Title level={4}>General</Title>
      </div>
      <List
        className="option_list"
        itemLayout="horizontal"
        dataSource={basicOptionsGeneral}
        renderItem={(item, index) => (
          <List.Item key={index} actions={item.actions}>
            <List.Item.Meta avatar={item.icon} title={item.title} description={item.description} />
            <div>{item.extra}</div>
          </List.Item>
        )}
      />
      <div className="title_wrapper">
        <Title level={4}>Students</Title>
      </div>
      <List
        className="option_list"
        itemLayout="horizontal"
        dataSource={addingNewStudentsOptions}
        renderItem={(item, index) => (
          <List.Item key={index} actions={item.actions}>
            <List.Item.Meta avatar={item.icon} title={item.title} description={item.description} />
            <div>{item.extra}</div>
          </List.Item>
        )}
      />
      <div className="title_wrapper">
        <Title level={4}>Sections</Title>
      </div>
      <List
        className="option_list"
        itemLayout="horizontal"
        dataSource={Object.values(project?.sections || {}).concat({
          id: nanoid(),
          name: 'New Section',
          mark: 0,
          statements: []
        })}
        renderItem={(v) => <AddSection project={project} section={v} />}
      />
      <div className="title_wrapper">
        <Title level={3}>Statement Bank Settings</Title>
      </div>
      <div className="title_wrapper">
        <Title level={4}>Adding New Statement Bank</Title>
      </div>
      <List
        className="option_list"
        itemLayout="horizontal"
        dataSource={addingNewStatementBankOptions}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta avatar={item.icon} title={<a href="#">{item.title}</a>} description={item.description} />
            <div>{item.extra}</div>
          </List.Item>
        )}
      />
      <div className="title_wrapper">
        <Title level={3}>Advanced Settings</Title>
      </div>
      <div className="title_wrapper">
        <Title level={4}>Options</Title>
      </div>
      <List
        className="option_list"
        itemLayout="horizontal"
        dataSource={advancedOptions}
        renderItem={(item, index) => (
          <List.Item key={index} actions={item.actions}>
            <List.Item.Meta avatar={item.icon} title={<a href="#">{item.title}</a>} description={item.description} />
            <div>{item.extra}</div>
          </List.Item>
        )}
      />
      {pageType === 'newProject' && (
        <div className="create_button_wrapper">
          <Button
            className="create_button"
            type="primary"
            onClick={() => {
              if ( project){
                if (project.checkTotalMark() == false){
                  message.warning("The sections don't add up to the total mark inputted, please check your inputs");
                }
                else if ( project.totalMark == 0){
                  message.warning("Project Total can't be 0");
                }
                else if (Object.values(project.sections).length == 0){
                  message.warning("Unable to create a project with no sections");
                }
                else{
                  project && db.addProjects(project);
                  setProjectByID(project.id)
                  message.info('Project created successfully', 0.5);
                  history.push('assessments?subpage=students');
                }
              }

            }}
          >
            Create
          </Button>
        </div>
      )}
      <Modal
        keyboard={false}
        centered
        title="Important Message"
        visible={isWarningVisible}
        onOk={hanldeWarningModalConfirm}
        onCancel={handleWarningModalCancel}
        footer={[
          <Button key="Delete" type="default" onClick={handleWarningModalCancel}>
            Ignore 
          </Button>,
          <Button className="modal_btn_danger" key="Delete" type="default" onClick={hanldeWarningModalConfirm}>
            Replace
          </Button>
        ]}
      >
        <p>
          You’ve chosen a new list of students and there are some duplicates, do you want to ignore them or replace
          them? If you replace duplicates you lose all previous markings on them.
        </p>
      </Modal>
      <Modal
        keyboard={false}
        centered
        title="Import Students from Other Projects"
        visible={isStudentsModalVisible}
        onOk={handleChooseStudentsOk}
        onCancel={handleChooseStudentsCancel}
        footer={[
          <Button key="Cancel" onClick={handleChooseStudentsCancel}>
            Cancel
          </Button>,
          <Button key="Save" type="primary" onClick={handleChooseStudentsOk}>
            Confirm
          </Button>
        ]}
      >
        <Select
          showSearch
          style={{ width: 400 }}
          placeholder="Select a project"
          optionFilterProp="children"
          onChange={onChangeProjectsStudents}
          filterOption={(input, option) => option?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {getProjects()}
        </Select>
        <TextArea autoSize />
      </Modal>
      <Modal
        keyboard={false}
        centered
        title="Import Statement Banks from Other Projects"
        visible={isSBModalVisible}
        onOk={handleChooseSBOk}
        onCancel={handleChooseSBCancel}
        footer={[
          <Button key="Cancel" onClick={handleChooseSBCancel}>
            Cancel
          </Button>,
          <Button key="Save" type="primary" onClick={handleChooseSBOk}>
            Confirm
          </Button>
        ]}
      >
        <Select
          showSearch
          style={{ width: 400 }}
          placeholder="Select a project"
          optionFilterProp="children"
          onChange={onChangeProjects}
          filterOption={(input, option) => option?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {getProjects()}
        </Select>
        <Divider orientation="left">Select A Section</Divider>
        <Select
          showSearch
          style={{ width: 400 }}
          placeholder="Select a section from project"
          optionFilterProp="children"
          onChange={onChangeSections}
          filterOption={(input, option) => option?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {selectSectionsDropdown.map((section) => (
            <Option key={section} value={section}>
              {section}{' '}
            </Option>
          ))}
        </Select>
        <Divider orientation="left">Select Section to Add Statements To</Divider>
        <Select
          showSearch
          style={{ width: 400 }}
          placeholder="Select a section to add statement bank to"
          optionFilterProp="children"
          onChange={onChangeSectionforProject}
          filterOption={(input, option) => option?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {getOptions()}
        </Select>
        {/* <TextArea autoSize /> */}
      </Modal>

      <Modal
        keyboard={false}
        centered
        title="Import Statement Banks From Your Computer"
        visible={isSectionModalVisible}
        onOk={handleSectionOK}
        onCancel={handleSectionCancel}
        footer={[
          <Button key="Cancel" onClick={handleSectionCancel}>
            Cancel
          </Button>,
          <Button key="Save" type="primary" onClick={handleSectionOK}>
            Confirm
          </Button>
        ]}
      >
        <Row gutter={16}>
          <Select
            showSearch
            style={{ width: 400 }}
            placeholder="Select a section"
            optionFilterProp="children"
            onChange={onChangeSections}
            filterOption={(input, option) => option?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {getOptions()}
          </Select>
        </Row>
        <Divider orientation="left">Upload Statement Banks</Divider>
        <Row gutter={16}>
          <Upload {...uploadProps} accept=".json" beforeUpload={handleJSONStatementBank}>
            <Button icon={<UploadOutlined />}> Import Statement Banks from Your Computer </Button>
          </Upload>
        </Row>
      </Modal>
    </div>
  );
};

export default Settings;
