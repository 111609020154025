import React, { useCallback, useEffect, useState } from 'react';
import { List, Typography, Upload, message, Button } from 'antd';
import { Link } from 'react-router-dom';
import { RcFile, UploadProps } from 'antd/lib/upload';
import { useHistory } from 'react-router-dom';
import { CaretUpFilled, FolderAddFilled, FolderFilled, SearchOutlined } from '@ant-design/icons';
import { useDB, useLayout } from '@src/hooks';
import { ExportToFileButton, Input, Modal } from '@src/components';
import { getProjectFromJSONString } from '@src/lib/project';
import { IProject } from '@src/interfaces';
import './index.less';

const UploadJSONButton: React.FC<UploadProps> = (props) => {
  const { db } = useDB();

  const handleUpload = useCallback(
    (file: RcFile, fileList: RcFile[]) => {
      let status = 'done';
      file.text().then((text) => {
        const project = getProjectFromJSONString(text);
        db.addProjects(project);
      });

      if (status !== 'uploading') {
        console.log(file, fileList);
      }
      if (status === 'done') {
        // message.success(`${file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        // message.error(`${file.name} file upload failed.`);
      }

      return false;
    },
    [db]
  );

  return (
    <Upload className="projects__upload" {...props} accept=".json" beforeUpload={handleUpload}>
      Import Project from Your Computer
    </Upload>
  );
};

const addProjectOptions = [
  {
    avatar: <CaretUpFilled className="green_icon" />,
    title: <UploadJSONButton />,
    description: 'A valid project should be a JSON exported by this software'
  },
  {
    avatar: <FolderAddFilled className="green_icon" />,
    title: (
      <Link className="link__btn" to="settings?pageType=newProject">
        Create New Project
      </Link>
    ),
    description: 'Create a new project directly'
  }
];

const Projects = () => {
  const { db, project, setProjectByID } = useDB();
  const history = useHistory();
  const [value, setValue] = useState('');

  const { updateLayoutState } = useLayout();
  const { Title } = Typography;
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  useEffect(() => {
    updateLayoutState({
      type: 'all',
      state: {
        title: 'Projects',
        sidebarDisableKeys: !project ? ['assessments', 'settings', 'statements'] : []
      }
    });
  }, [updateLayoutState, project]);

  const handleProjectItemClick = useCallback(
    (project: IProject) => {
      setProjectByID(project.id);
      history.push('assessments?subpage=students');
    },
    [setProjectByID, history]
  );

  const handleStatementBankClick = useCallback(
    (project: IProject) => {
      setProjectByID(project.id);
      history.push('statements');
    },
    [setProjectByID, history]
  );

  const handleReportsClick = useCallback(
    (project: IProject) => {
      setProjectByID(project.id);
      history.push('assessments?subpage=reports');
    },
    [setProjectByID, history]
  );

  const handleSettingsClick = useCallback(
    (project: IProject) => {
      setProjectByID(project.id);
      history.push(`settings?pageType=settings&projectId=${project.id}`);
    },
    [setProjectByID, history]
  );

  const handleModalCancel = () => {
    setIsDeleteModalVisible(false);
  };

  const hanldeModalConfirm = () => {
    setIsDeleteModalVisible(false);
    const deleteMessage = message.loading('Removing it from project database...', 0);
    setTimeout(deleteMessage, 1000);
  };



  return (
    <div className="list-page-layout page-layout">
      <Input addonBefore={<SearchOutlined />} placeholder="Search for projects"  onChange={(e)=>{setValue(e.target.value)}}/>
      <div className="title_wrapper" style={{ marginTop: '30px' }}>
        <Title level={4}>Adding New Projects</Title>
      </div>
      <List
        className="option_list"
        itemLayout="horizontal"
        dataSource={addProjectOptions}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta avatar={item.avatar} title={item.title} description={item.description} />
          </List.Item>
        )}
      />
      <div className="title_wrapper">
        <Title level={4}>Your Projects</Title>
      </div>
      <List
        className="option_list"
        itemLayout="horizontal"
        dataSource={Object.values(db.projects).filter(p=>!!p.name.toLowerCase().match(value.toLowerCase()))}
        renderItem={(project) => (
          <List.Item
            actions={[
              <Button key="settings" type="link" onClick={() => handleSettingsClick(project)}>
                Settings
              </Button>,
              <ExportToFileButton
                key={project.id}
                format="json"
                filename={`project_${project.id}.json`}
                dataString={encodeURIComponent(project.toJSON())}
              />,
              <Button key="statments" type="link" onClick={() => handleStatementBankClick(project)} > 
              Statement Bank
            </Button>,
              <Button key="reports" type="link" onClick={() => handleReportsClick(project)}>
                Reports
              </Button>,
              <Button
                key="delete"
                type="link"
                className="danger__btn"
                onClick={() => {
                  db.deleteProjectById(project.id);
                }}
              >
                Delete
              </Button>
            ]}
          >
            <List.Item.Meta
              avatar={<FolderFilled className="blue_icon" />}
              title={<a onClick={() => handleProjectItemClick(project)}>{project.name}</a>}
              description={project.date?.toDateString()}
            />
          </List.Item>
        )}
      />
      <Modal
        keyboard={false}
        centered
        title="Important Message"
        visible={isDeleteModalVisible}
        onOk={hanldeModalConfirm}
        onCancel={handleModalCancel}
        footer={[
          <Button key="Cancel" onClick={handleModalCancel}>
            Cancel
          </Button>,
          <Button className="modal_btn_danger" key="Delete" type="default" onClick={hanldeModalConfirm}>
            Delete
          </Button>
        ]}
      >
        <p>Delete process cannot be undone</p>
      </Modal>
    </div>
  );
};

export default Projects;
