import React, { useState } from 'react';
import { AutoComplete, Input, InputProps } from 'antd';
import './index.less';
const mockVal = (str: string, repeat: number = 1) => {
  return {
    value: str.repeat(repeat)
  };
};

const InputArea: React.FC<InputProps> = (props) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const onSearch = (searchText: string) => {
    setOptions(!searchText ? [] : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)]);
  };

  return (
    <AutoComplete open={false} className="autocomplete" options={options} onSelect={setInputValue} onSearch={onSearch}>
      <Input {...props} />
    </AutoComplete>
  );
};

export default InputArea;
