import React, { useMemo } from 'react';
import { Input, Tooltip, Checkbox } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import './index.less';
import { ISection } from '@src/interfaces';
import { useDB } from '@src/hooks';
const { TextArea } = Input;
const Item: React.FC<{ section: ISection; statementId: string; commentType: 'statementBank' | 'student' }> = ({
  section,
  statementId,
  commentType
}) => {
  const { student, project } = useDB();
  const statement = useMemo(() => project?.getStatementById(section.id, statementId), [project, section, statementId]);
  const checked = useMemo(() => student?.sections[section.id].statementIds.includes(statementId), [
    student,
    statementId,
    section
  ]);

  const handleDelete = () => {
    if (commentType === 'statementBank') project?.deleteStatementGlobally(section.id, statementId);
  };

  const handleEdit = (e: any) => {
    const {
      target: { innerHTML: value }
    } = e;
    if (value.trim() === '') return;
    project?.editStatement(section.id, statementId, value);
  };

  const handleCheck = (e: any) => {
    const _checked = e.target.checked;

    if (project && student) {
      if (_checked) {
        project.addStatementsToStudent(section.id, student.id, [statementId]);
      } else {
        project.deleteStatementfromStudent(section.id, student.id, [statementId]);
      }
    }
  };

  return (
    <div className="comment__bar">
      <TextArea
        className="comment__input"
        placeholder="Edit comment"
        rows={4}
        autoSize={{ minRows: 2 }}
        defaultValue={statement?.text}
        onPressEnter={handleEdit}
      />
      <div className="comment_icon_wrapper">
        {commentType === 'statementBank' && (
          <Tooltip title="Delete">
            <CloseCircleFilled className="comment__icon" onClick={handleDelete} />
          </Tooltip>
        )}
        {commentType === 'student' && <Checkbox onChange={handleCheck} defaultChecked={checked} checked={checked} />}
      </div>
    </div>
  );
};
export default Item;
