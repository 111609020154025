import React from 'react';
import Comment from '../Comment';
import { Row, Col } from 'antd';
import { ISection, IStatement } from '@src/interfaces';
import { useDB } from '@src/hooks';
import './index.less';

const List: React.FC<{ section: ISection; commentType: 'statementBank' | 'student' }> = ({ section, commentType }) => {
  const { project } = useDB();
  return (
    <div>
      <Row className="comment_list" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {section.statements.map((v) => {
          const statement = project?.getStatementById(section.id, v.id) as IStatement;
          return (
            <Col
              key={v.id}
              className="gutter-row"
              span={statement.text.length >= 130 ? 24 : statement.text.length >= 50 ? 12 : 6}
            >
              <Comment key={v.id} section={section} statementId={statement.id} commentType={commentType} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default List;
