import React, { useState, useEffect, useCallback } from 'react';
import { List, Typography, Tooltip, Table, Button } from 'antd';
import { FundFilled, MessageFilled, FileTextFilled, ReadFilled, EyeFilled, DownSquareFilled } from '@ant-design/icons';
import { useDB, useLayout } from '@src/hooks';
import { nanoid } from 'nanoid';
import { CSVLink } from 'react-csv';
import './index.less';

interface IStudentFeedbackComponent {
  key: string;
  name: string;
  comments: Array<any>;
  mark: number;
}
interface IStudentRow {
  key: string;
  name: string;
  username: string;
  feedback: React.ReactNode;
  totalMark: number;
}

const reportDataSource: IStudentRow[] = [];
const reportColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username'
  },
  {
    title: 'Feedback',
    dataIndex: 'feedback',
    key: 'feedback'
  },
  {
    title: 'Total',
    dataIndex: 'totalMark',
    key: 'totalMark'
  }
];

const feedbackColumns = [
  {
    title: 'Section',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Comments',
    dataIndex: 'comments',
    key: 'comments'
  },
  {
    title: 'Mark',
    dataIndex: 'mark',
    key: 'mark'
  }
];

const basicOptionsGeneral = [
  {
    avatar: <FundFilled className="green_icon" />,
    title: 'All Feedbacks',
    description: 'A csv file containing all of your feedbacks for students.',
    extra: null
  }
];

const Reports = () => {
  const { updateLayoutState } = useLayout();
  const { project } = useDB();
  const { Title } = Typography;
  const csvData: object[] = [];
  const csvHeaderArr = ['Name', 'Username', 'Total'];
  const [previewClicked, setPreviewClicked] = useState(false);

  useEffect(() => {
    if (previewClicked == false) {
      reportDataSource.splice(0);
    }
    if (project?.students) {
      Object.values(project.students).filter((student) => {
        const studentSections = student.sections;
        const feedbackTable: Array<IStudentFeedbackComponent> = [];
        if (studentSections) {
          const totalMark = Object.values(student.sections)
            .map((i) => i.mark)
            .reduce((prev, curr) => prev + curr);
          Object.values(student.sections).map((item) =>
            feedbackTable.push({
              key: nanoid(10),
              name: project.getSectionName(item.id),
              comments: project
                .getStudentSectionStatements(item.id, student.id)
                .map((i) => <div key={i?.id}>{`- ${i?.text}`}</div>),
              mark: item.mark
            })
          );
          reportDataSource.push({
            key: nanoid(10),
            name: student.name,
            username: student.username,
            feedback: <Table dataSource={feedbackTable} columns={feedbackColumns} pagination={false} bordered />,
            totalMark: totalMark
          });
        }
      });
    }
  }, [previewClicked]);

  useEffect(() => {
    updateLayoutState({
      type: 'partial',
      state: {
        title: 'Student reports',
        subTitle: '',
        extra: null
      }
    });
  }, [updateLayoutState]);

  if (project?.students && project.sections) {
    Object.values(project?.sections).map((item) => {
      csvHeaderArr.splice(-1, 0, `${item.name} Comments`);
      csvHeaderArr.splice(-1, 0, `${item.name} Marks`);
    });
    Object.values(project.students).filter((student) => {
      const studentSections = student.sections;
      const csvObject: any = {};
      csvHeaderArr.map((name) => {
        csvObject[name] = null;
      });
      if (studentSections) {
        const totalMark = Object.values(student.sections)
          .map((i) => i.mark)
          .reduce((p, c) => p + c);
        Object.assign(csvObject, { Name: student.name, Username: student.username, Total: totalMark });
        Object.values(student.sections).map((item) => {
          csvObject[`${project.getSectionName(item.id)} Comments`] = project
            .getStudentSectionStatements(item.id, student.id)
            .map((i) => `- ${i?.text}\n`)
            .toString()
            .replaceAll('\n,', '\n');
          csvObject[`${project.getSectionName(item.id)} Marks`] = item.mark;
        });
        csvData.push(csvObject);
      }
    });
  }

  return (
    <div className="list-page-layout">
      <div className="title_wrapper">
        <Title level={4}>Options</Title>
      </div>
      <List
        className="option_list"
        itemLayout="horizontal"
        dataSource={basicOptionsGeneral}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta avatar={item.avatar} title={<a href="#">{item.title}</a>} description={item.description} />
            <div>
              <Button
                onClick={() => {
                  setPreviewClicked(!previewClicked);
                }}
                className="report_preview_btn"
              >
                Preview
              </Button>
              <CSVLink filename={'All Feedback.csv'} data={csvData} className="btn btn-primary">
                Download
              </CSVLink>
            </div>
          </List.Item>
        )}
      />
      {previewClicked ? <Table dataSource={reportDataSource} columns={reportColumns} bordered /> : null}
    </div>
  );
};

export default Reports;
