import { set, get } from 'idb-keyval';
import { IProjects, IProjectConstruct } from '@src/interfaces/';
import { Project } from './project';

export class Database {
  projects: IProjects = {};

  constructor(mock: boolean = false) {
    // read existed projects from indexDB
    this.getFromBrowser().then((projects) => {
      projects && this.addProjectsObj(projects);
    });
  }

  addProjectsObj(projects: Record<string, Omit<IProjectConstruct, 'db'>>) {
    const curProjects = {} as IProjects;
    Object.entries(projects).forEach(([k, v]) => {
      curProjects[k] = new Project(v.name, v.id, v.date, v.students, v.sections, v.totalMark, this, v.hyperparameters);
    });
    this.projects = { ...this.projects, ...curProjects };
    this.saveToBrowser();
  }

  addProjects(...projects: Array<Project>) {
    projects.forEach((project) => {
      if (project instanceof Project) {
        project.db = this;
        this.projects[project.id] = project;
      }
    });
    this.saveToBrowser();
  }

  deleteProjectById(projectId: string) {
    delete this.projects[projectId];
    this.saveToBrowser();
  }

  getProjects() {
    return this.projects;
  }

  getProject(projectID: string) {
    return this.projects[projectID] || null;
  }

  getFromBrowser(): Promise<Record<string, Omit<IProjectConstruct, 'db'>>> {
    return get('projects') as Promise<Record<string, Omit<IProjectConstruct, 'db'>>>;
  }

  saveToBrowser() {
    document.dispatchEvent(new CustomEvent('onDBChange'));
    set('projects', this.projects);
  }

  saveOnQuit() {
    alert('quitting test');
  }


  exportProjectToJSON(projectID: string) {
    // var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.projects[projectID]));
    // var dlAnchorElem = document.getElementById('downloadAnchorElem');
    // dlAnchorElem.setAttribute("href",     dataStr     );
    // dlAnchorElem.setAttribute("download", `project_${projectID}.json`);
    // dlAnchorElem.click();
    console.log(this.projects[projectID]);
  }
  importFromJSON(json: string) {
    const data = JSON.parse(json);
    Object.assign(this, data);
  }

  exportToJSON() {
    return JSON.stringify(this);
  }

}

export const db = new Database();
