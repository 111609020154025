import React, { useEffect, useCallback, useState } from 'react';
import { useDB, useLayout, useQuery } from '@src/hooks';
import { Menu, MenuProps } from 'antd';
import Marking from './Marking';
import Reports from './Reports';
import Students from './Students';
import { useHistory } from 'react-router';
import { getUrlParams } from '@src/utils/url';

const AssessmentMenu: React.FC<MenuProps> = (props) => {
  const { student } = useDB();
  return (
    <Menu {...props} mode="horizontal">
      <Menu.Item key="students" style={{ userSelect: 'none' }}>
        Students
      </Menu.Item>
      <Menu.Item key="marking" disabled={!student} style={{ userSelect: 'none' }}>
        Marking
      </Menu.Item>
      <Menu.Item key="reports" style={{ userSelect: 'none' }}>
        Reports
      </Menu.Item>
    </Menu>
  );
};

const Assessment = () => {
  const { updateLayoutState } = useLayout();
  const [subpage, setSubpage] = useState<React.ReactNode>();
  const subpageKey = useQuery().get('subpage') || 'students';
  const history = useHistory();

  const handleMenuSelect = useCallback(
    ({ key }) => {
      history.push(`assessments?subpage=${key}`);
    },
    [history]
  );

  useEffect(() => {
    updateLayoutState({
      type: 'all',
      state: {
        menu: <AssessmentMenu onSelect={handleMenuSelect} selectedKeys={[subpageKey]} />
      }
    });
  }, [updateLayoutState, handleMenuSelect, subpageKey]);

  useEffect(() => {
    switch (subpageKey) {
      case 'students':
        setSubpage(<Students />);
        break;
      case 'marking':
        setSubpage(<Marking />);
        break;
      case 'reports':
        setSubpage(<Reports />);
        break;
    }
  }, [subpageKey]);

  return <div className="page-layout">{subpage}</div>;
};

export default Assessment;
