import React, { useEffect,useState} from 'react';
import { useDB, useLayout } from '@src/hooks';
import { SearchOutlined, ProfileFilled, DownloadOutlined } from '@ant-design/icons';
import { List, Input, ExportToFileButton } from '@components/';
import './index.less';

const Statement = () => {
  const { updateLayoutState } = useLayout();
  const { project } = useDB();
  const [value, setValue] = useState('');

  let statementBankJson: any = project?.statementBanksToJSON();

  useEffect(() => {
    updateLayoutState({
      type: 'all',
      state: {
        title: 'Statement Bank'
      }
    });
  }, [updateLayoutState]);
  return (
    <div className="page-layout statement-page">
      <div className="sb_input_wrapper">
        <Input addonBefore={<SearchOutlined />} onChange={(e)=>{setValue(e.target.value)}} placeholder="Search statements" />
      </div>

      {project?.sections &&
        Object.values(project.sections).map((v) => {
          return (
            <>
              <div className="sb_indicator_wrapper">
                <div className="section__wrap-left">
                  <ProfileFilled className="section__wrap-icon" />
                  <div className="section__wrap-title">{v.name}</div>
                </div>
                <ExportToFileButton
                  key={project?.id}
                  format="json"
                  filename={`statementBank_${project?.id}_${v.name}`}
                  dataString={encodeURIComponent(project.sectionToJson(v.id))}
                />
              </div>
              <List section={{...v,statements:v.statements.filter(statement=>!!statement.text.toLowerCase().match(value.toLowerCase()))}} commentType="statementBank"/>
            </>
          );
        })}
    </div>
  );
};

export default Statement;
