import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from 'antd';
import { useDB } from '@src/hooks';
import { SmileFilled } from '@ant-design/icons';
import { IStudent } from '@src/interfaces';
import '../index.less';

const Student: React.FC<{ student: IStudent }> = ({ student }) => {
  const { setStudentByID } = useDB();
  const { Title } = Typography;
  let marked = false;
  const history = useHistory();

  const handleStudentClick = useCallback(() => {
    setStudentByID(student.id);
    history.push('assessments?subpage=marking');
  }, [history, setStudentByID, student]);

  for (let { mark } of Object.values(student.sections)) {
    if (mark) {
      marked = Boolean(mark);
    }
  }

  return (
    <div className="student_component">
      {marked ? <SmileFilled className="blue_icon" /> : <SmileFilled className="gray_icon" />}
      <div
        className="student_info"
        onClick={() => {
          setStudentByID(student.id);
        }}
      >
        <Button type="link" onClick={handleStudentClick} style={{ textAlign: 'left', padding: 0 }}>
          <Title level={5} className="student_name" style={{ color: marked ? '#007aff' : '#686868' }}>
            {student.name}
          </Title>
        </Button>
        <Title level={5} className="student_username">
          {student.username}
        </Title>
      </div>
    </div>
  );
};

export default Student;
