import React, { useCallback, useEffect, useState,useMemo } from 'react';
import { Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useDB, useLayout } from '@src/hooks';
import { Input } from '@components/';
import StudentsList from './StudentsList';
import { IStudent } from '@src/interfaces';
import './index.less';

const Students: React.FC = () => {
  const { updateLayoutState } = useLayout();
  const { project } = useDB();
  const { Title } = Typography;
  const [value, setValue] = useState('');
  useEffect(() => {
    updateLayoutState({
      type: 'partial',
      state: {
        title: 'Students',
        subTitle: '',
        extra: null
      }
    });
  }, [updateLayoutState]);


  const filterStudents = useCallback(
    (marked: boolean) => {
      if (project?.students) {
        return Object.values(project.students).filter((student) => {
          const studentSections = student.sections;
          if (studentSections) {
            for (let { mark } of Object.values(studentSections)) {
              if (mark) {
                return marked ? mark : !mark;
              }
            }
          }
          return !marked;
        });
      }
      return [] as Array<IStudent>;
    },
    [project]
  );

  return (
    <div className="students__page">
      <Input onChange={(e)=>{setValue(e.target.value)}} addonBefore={<SearchOutlined />} placeholder="Search for students" />
      <div className="students__wrap">
        <Title level={4} className="marked__title">
          Marked
        </Title>
        <StudentsList students={filterStudents(true).filter(student => !!student.name.toLowerCase().match(value.toLowerCase()))} />
      </div>
      <div className="students__wrap">
        <Title level={4} className="marked__title">
          Unmarked
        </Title>
        <StudentsList students={filterStudents(false).filter(student => !!student.name.toLowerCase().match(value.toLowerCase()))} />
      </div>
    </div>
  );
};

export default Students;
