import { readString } from 'react-papaparse';
import { IStatement, IStudent, IDatabase, IHyperparams, ISections, IProjectConfig, ISection } from '@src/interfaces';
import { db } from './database';
import { nanoid } from 'nanoid';
export class Project {
  name: string;
  id: string;
  annonymous?: boolean;
  students: { [studentId: string]: IStudent };
  sections: ISections;
  totalMark: number;
  date: Date;
  db: IDatabase;
  hyperparameters: IHyperparams;
  constructor(
    name: string,
    id: string,
    date: string,
    students: Array<IStudent>,
    sections: ISections,
    totalMark: number,
    db: IDatabase,
    hyperparameters: IHyperparams
  ) {
    this.name = name;
    this.id = id;
    this.sections = sections;
    this.totalMark = totalMark;
    this.date = new Date(date);
    this.students = {};
    if (students instanceof Array) {
      students.forEach((student) => (this.students[student.id] = student));
    } else if (!!students) {
      this.students = students;
    }
    this.db = db;
    this.hyperparameters = hyperparameters;
  }

  // TODO: A more general way to set the general config and fix not updating bug?
  setConfigs(key: keyof IProjectConfig, value: any) {
    switch (key) {
      case 'name':
        this.name = value;
        break;
      case 'totalMark':
        this.totalMark = value;
        break;
      case 'hyperparameters':
        this.hyperparameters = {
          ...this.hyperparameters,
          ...value
        };
        break;
      case 'date':
        this.date = value;
        break;
    }
    this.db.saveToBrowser();
  }

  addSection(section: ISection) {
    this.sections[section.id] = section;
    Object.values(this.students).forEach((student) => {
      const currentStudetnSection = student.sections[section.id];
      let updateStudentSection = {
        id: section.id,
        name: section.name,
        mark: 0,
        statementIds: [] as string[]
      };
      if (currentStudetnSection) {
        updateStudentSection['mark'] =
          currentStudetnSection.mark > section.mark ? section.mark : currentStudetnSection.mark;
        updateStudentSection['statementIds'] = currentStudetnSection.statementIds.length
          ? currentStudetnSection.statementIds
          : [];
      }

      student.sections[section.id] = updateStudentSection;
    });
    this.db.saveToBrowser();
  }

  deleteSection(sectionId: string) {
    delete this.sections[sectionId];
    Object.values(this.students).forEach((student) => {
      delete student.sections[sectionId];
    });
    this.db.saveToBrowser();
  }

  // Statements
  addStatementBank(sectionId: string, statementBank: Array<IStatement>) {
    this.sections[sectionId].statements.concat(statementBank);
    this.db.saveToBrowser();
  }

  addStatement(sectionId: string, text: string) {
    const statement = {
      text: text,
      id: nanoid()
    };
    this.sections[sectionId].statements.push(statement);
    this.db.saveToBrowser();
    return statement;
  }

  addStatementsToStudent(sectionId: string, studentId: string, statemenIds: Array<string>) {
    const student = this.students[studentId];
    statemenIds.forEach((id) => {
      const existed = student.sections[sectionId].statementIds.includes(id);
      if (!existed) {
        student.sections[sectionId].statementIds.push(id);
      }
    });
    this.db.saveToBrowser();
  }

  // hard replace
  updateStatementsOfStudent(sectionId: string, studentId: string, statemenIds: Array<string>) {
    this.students[studentId].sections[sectionId].statementIds = statemenIds;
    this.db.saveToBrowser();
  }

  getStatements(sectionId: string) {
    return this.sections[sectionId].statements;
  }

  editStatement(sectionId: string, statementId: string, text: string) {
    console.log('edit', statementId);
    this.sections[sectionId].statements = this.sections[sectionId].statements.map((item) => {
      if (item.id === statementId) {
        const newStatement = { text };
        return { ...item, ...newStatement };
      }
      return item;
    });
    this.db.saveToBrowser();
  }

  getStatementById(sectionId: string, statementId: string) {
    const statement = this.sections[sectionId].statements.find((item) => item.id === statementId);
    return statement;
  }

  getStudentSectionStatements(sectionId: string, studentId: string) {
    const statmentsList: Array<IStatement | undefined> = [];
    const statementIDList = this.students[studentId].sections[sectionId].statementIds;
    statementIDList.map((item) => {
      statmentsList.push(this.getStatementById(sectionId, item));
    });
    return statmentsList;
  }

  getSectionName(sectionId: string) {
    return this.sections[sectionId].name;
  }

  deleteStatementfromStudent(sectionId: string, studentId: string, statementIds: Array<string>) {
    this.students[studentId].sections[sectionId].statementIds = this.students[studentId].sections[
      sectionId
    ].statementIds.filter((id) => !statementIds.includes(id));
    this.db.saveToBrowser();
  }

  deleteStatementGlobally(sectionId: string, statementId: string) {
    this.sections[sectionId].statements = this.sections[sectionId].statements.filter(
      (statement) => statement.id !== statementId
    );
    Object.values(this.students).forEach((student) => {
      student.sections[sectionId].statementIds = student.sections[sectionId].statementIds.filter(
        (id) => id !== statementId
      );
    });
    this.db.saveToBrowser();
  }

  getStudents() {
    return Object.values(this.students);
  }

  getStudentById(studentId: string) {
    return this.students[studentId];
  }

  addStudents(...students: Array<IStudent>) {
    students.forEach((student) => (this.students[student.id] = student));
    this.db.saveToBrowser();
  }

  addStudent(Name: string, Username: string) {
    let newStudent: IStudent = {
      id: nanoid(),
      username: Username,
      name: Name,
      sections: {}
    };

    for (var x in this.sections) {
      let updateStudentSection = {
        id: this.sections[x].id,
        name: this.sections[x].id,
        mark: 0,
        statementIds: [] as string[]
      };

      newStudent.sections[updateStudentSection.id] = updateStudentSection;
    }

    this.students[newStudent.id] = newStudent;

    this.db.saveToBrowser();
  }

  markStudent(studentId: string, sectionId: string, sectionMark: number) {
    this.students[studentId].sections[sectionId].mark = sectionMark;
    this.db.saveToBrowser();
  }

  toJSON() {
    return JSON.stringify({ ...this, db: undefined });
  }

  getScoringMatrix() {
    // this.students.map(())
  }

  addStudentsFromCSV(content: string) {
    let studentJson = readString(content as string, { header: true });
    console.log(studentJson);
  }

  statementBanksToJSON() {
    return JSON.stringify({ ...this.sections, db: undefined });
  }

  sectionToJson(id: string) {
    return JSON.stringify({ ...this.sections[id].statements, db: undefined });
  }

  //This is for creating the appropriate format to be used by the machine learning technologies
  createBooleanVector(id: string) {
    let boolLength = this.sections[id].statements.length;

    let vectorArrays: any = [];

    Object.values(this.students).forEach((student) => {
      let emptyVector = new Array(boolLength).fill(0);

      Object.values(student.sections[id].statementIds).forEach((index) => {
        emptyVector[Number(index)] = 1;
      });
      //Add on the mark
      emptyVector.push(student.sections[id].mark);
      //Push on the
      vectorArrays.push(emptyVector);
    });

    return vectorArrays;
  }

  checkTotalMark(){

    let count = 0;
    for (var x in this.sections){

      count += this.sections[x].mark
    }

    if (count == this.totalMark){
      return true;
    }
    else{
      return false;
    }
  }

  addStudentsFromCSVUpload( text: string){
    
    const students = getStudentsFromCSVString(text);

    var newStudent : IStudent  = {
      id : nanoid(),
      username: '',
      name: '',
      sections : {}
    };

    //Add the necessary sections
    for ( var x in this.sections){
      let updateStudentSection = {
        id: this.sections[x].id,
        name: this.sections[x].name,
        mark: 0,
        statementIds: [] as string[]
      }
      newStudent.sections[updateStudentSection.id] = updateStudentSection;
    }


    students.forEach( (student) =>{

      var tryNew : IStudent  = {
        id : nanoid(),
        username: student.username,
        name: student.name,
        sections : JSON.parse(JSON.stringify(newStudent.sections))
      };

      this.students[tryNew.id] = tryNew;

    })

    this.db.saveToBrowser();
  }

  checkForDuplicateStudents() {

    var dict: { [id: string] : string; } = {};
    let duplicates: number[] = [];
    let index = 0;

    Object.values(this.students).forEach((student) => {
       if (!( student.name in dict)){
        dict[student.name] = student.username;
       }
       else{

        if ( dict[student.name] == student.username) {
          duplicates.push(index);
        }

       }

       index ++;

    });

    return duplicates; 
  }

  deleteDuplicates() {

    let dupes = this.checkForDuplicateStudents();

    let i = 0;

    Object.values(this.students).forEach((student) => {
      if (i in dupes){
        delete this.students[student.id];
      }
      i ++; 
    })

    this.db.saveToBrowser();
  }

  replaceDuplicates(){

    var newStudent : IStudent  = {
      id : nanoid(),
      username: '',
      name: '',
      sections : {}
    };

    //Add the necessary sections
    for ( var x in this.sections){
      let updateStudentSection = {
        id: this.sections[x].id,
        name: this.sections[x].name,
        mark: 0,
        statementIds: [] as string[]
      }
      newStudent.sections[updateStudentSection.id] = updateStudentSection;
    }

    let dupes = this.checkForDuplicateStudents();

    let i = 0;

    let all : string[] = [];



    Object.values(this.students).forEach((student) => {
      if (i in dupes){
        all.push(student.username);
        delete this.students[student.id];
      }
      i ++; 
    })

    Object.values(this.students).forEach((student) => {
      if (student.username in all){
        student.sections = newStudent.sections
      }
    })
    this.db.saveToBrowser();

  }

}

  

export const getProjectFromJSONString = (json: string) => {
  const data = JSON.parse(json);
  const project = new Project(
    data.name,
    data.id,
    data.date,
    data.students,
    data.sections,
    data.totalMark,
    db,
    data.hyperparameters
  );
  return project;
};

export const getStudentsFromCSVString = (csv: string) => {
  const JSONed = readString(csv, { header: true }).data;
  return JSONed.map((item: any) => {
    return {
      id: nanoid(),
      username: item['Username'],
      name: item['First Name'] + ' ' + item['Last Name'],
      sections: {}
    };
  });
};

export const getStatementBankFromJSONString = (json: string) => {
  const data = JSON.parse(json);
  return data;
};

export const getStatementVectorByStatementIds = (statements: IStatement[], statementIds: string[]): number[] =>
  statements.map((statement) => (statementIds.includes(statement.id) ? 1 : 0));

export const getDatasetBySectionIdFromStudents = (
  statements: IStatement[],
  sectionId: string,
  students: IStudent[]
): { xSet: number[][]; ySet: number[] } => {
  const SectionOfStudents = students.map((student: IStudent) => student.sections[sectionId]);
  const xSet = SectionOfStudents.map((section) => getStatementVectorByStatementIds(statements, section.statementIds));
  const ySet = SectionOfStudents.map((section) => section.mark);

  return {
    xSet,
    ySet
  };
};
function str(name: string) {
  throw new Error('Function not implemented.');
}

