import React, { useCallback, useEffect, useState } from 'react';
import { DatabaseFilled, SettingFilled, ScheduleFilled, BankFilled } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import './index.less';
import { useHistory } from 'react-router-dom';
import { useLayout } from '@src/hooks';
import { ISidebarKeys } from '@src/interfaces';

interface IMenuItem {
  title: string;
  icon: React.ReactNode;
  route: ISidebarKeys;
}

const MenuItem: React.FC<Omit<IMenuItem, 'key'>> = (props) => {
  return (
    <div className="side__menu-item">
      {props.icon}
      <div className="side__menu-tiem-text">{props.title}</div>
      {props.children}
    </div>
  );
};

const SideMenuItems: Array<IMenuItem> = [
  {
    title: 'Projects',
    icon: <DatabaseFilled />,
    route: 'projects'
  },
  {
    title: 'Assessment',
    icon: <ScheduleFilled />,
    route: 'assessments'
  },
  {
    title: 'Statement Bank',
    icon: <BankFilled />,
    route: 'statements'
  },
  {
    title: 'Settings',
    icon: <SettingFilled />,
    route: 'settings'
  }
];

const Sidebar: React.FC = () => {
  const {
    layoutState: { sidebarDisableKeys }
  } = useLayout();
  const history = useHistory();
  const [selects, setSelects] = useState<Array<ISidebarKeys>>(['projects']);

  useEffect(() => {
    history.listen((location) => {
      const pathname = location.pathname.replace('/', '');
      if (['projects', 'assessments', 'statements', 'settings'].includes(pathname)) {
        setSelects([pathname as ISidebarKeys]);
      }
    });
  }, [history]);

  const getDisableStatus = useCallback(
    (key: ISidebarKeys): boolean => {
      return sidebarDisableKeys ? sidebarDisableKeys.includes(key) : false;
    },
    [sidebarDisableKeys]
  );

  const handleMenuSelect = useCallback(
    ({ key }) => {
      history.push(key);
    },
    [history]
  );

  return (
    <Layout.Sider className="side__bar" theme="light" width={80}>
      <Menu selectedKeys={selects} onSelect={handleMenuSelect}>
        {SideMenuItems.map((item) => (
          <Menu.Item key={item.route} disabled={getDisableStatus(item.route)}>
            <MenuItem title={item.title} route={item.route} icon={item.icon} />
          </Menu.Item>
        ))}
      </Menu>
    </Layout.Sider>
  );
};

export default Sidebar;
