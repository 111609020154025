import React from 'react';
import { PageHeader } from 'antd';
import { useLayout } from '@src/contexts/LayoutContext';
import './index.less';

const Nav: React.FC = (props) => {
  const {
    layoutState: { title, menu, subTitle, extra }
  } = useLayout();
  return (
    <div className="nav__header">
      <PageHeader title={title} footer={menu} subTitle={subTitle} extra={extra} />
    </div>
  );
};
export default Nav;
